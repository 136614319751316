import React, { useMemo } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    layout,
    contentWrapper,
    paragraphsRow,
    accessoriesWrapper,
    accessoriesContainer,
    accessoriesImageWrapper,
    accessoriesButton,
    headerContainer,
} from './style-accessories.module.scss';
import { grid, gridExtended } from '../../styles/grid.module.scss';

import { IMedia } from '../../models/media.model';
import prependStrapiUrl from '../../utils/prepend-strapi-url';

import StyleHeader from '../atoms/style-header';
import StyleSubheader from '../atoms/style-subheader';
import StyleParagraph from '../atoms/style-paragraph';
import Button from '../atoms/button';

interface IStyleAccessories {
    name: string;
    accessoriesParagraph1: string;
    accessoriesImageData: IMedia;
}

const StyleAccessories: React.FC<IStyleAccessories> = ({
    name,
    accessoriesParagraph1,
    accessoriesImageData,
}) => {
    const { t } = useI18next();

    const params = [
        {
            key: 'styl',
            value: name,
        },
    ];

    const accessoriesImageWrapperStyle = useMemo(
        () => ({
            backgroundImage: `url(${prependStrapiUrl(accessoriesImageData.url)})`,
        }),
        [accessoriesImageData.url]
    );

    return (
        <div className={`${grid} ${gridExtended} ${layout}`}>
            <div className={contentWrapper}>
                <div className={headerContainer}>
                    <StyleSubheader text={t('style.page.accessories.subheader')} />
                    <StyleHeader text={t('style.page.accessories.header')} />
                </div>
                <div className={paragraphsRow}>
                    <StyleParagraph>{accessoriesParagraph1}</StyleParagraph>
                </div>
            </div>
            <div className={`${accessoriesWrapper}`}>
                <div className={accessoriesImageWrapper} style={accessoriesImageWrapperStyle}></div>
                <div className={accessoriesContainer}>
                    <Button
                        className={accessoriesButton}
                        to={'/soplica-weselna/generator'}
                        params={params}
                        as={'link'}
                    >
                        {t('style.page.link.to.creator')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default StyleAccessories;
