// extracted by mini-css-extract-plugin
export var backgroundAnimation = "bottle-presentation-module--backgroundAnimation--71128";
export var bottleImageRatio = "bottle-presentation-module--bottle-image-ratio--dc6af";
export var errorBlink = "bottle-presentation-module--error-blink--1ee88";
export var header = "bottle-presentation-module--header--8edab";
export var imageWrapper = "bottle-presentation-module--imageWrapper--9e155";
export var layout = "bottle-presentation-module--layout--887e4";
export var layoutBirthday = "bottle-presentation-module--layout-birthday--718d9";
export var layoutLemon = "bottle-presentation-module--layout-lemon--618b0";
export var lemonBottle = "bottle-presentation-module--lemon-bottle--51cfd";
export var paragraphOne = "bottle-presentation-module--paragraph-one--46faf";
export var subheader = "bottle-presentation-module--subheader--8b556";
export var wrapper = "bottle-presentation-module--wrapper--5b5b4";
export var wrapperReverse = "bottle-presentation-module--wrapperReverse--ba871";