// extracted by mini-css-extract-plugin
export var accessoriesButton = "style-accessories-module--accessories-button--f2dc5";
export var accessoriesContainer = "style-accessories-module--accessories-container--c0bea";
export var accessoriesImageWrapper = "style-accessories-module--accessories-image-wrapper--cdbe7";
export var accessoriesWrapper = "style-accessories-module--accessories-wrapper--16a96";
export var backgroundAnimation = "style-accessories-module--backgroundAnimation--0e68f";
export var contentWrapper = "style-accessories-module--content-wrapper--a538b";
export var errorBlink = "style-accessories-module--error-blink--dd7b3";
export var headerContainer = "style-accessories-module--header-container--af97f";
export var layout = "style-accessories-module--layout--9d21a";
export var paragraphsRow = "style-accessories-module--paragraphs-row--16d70";