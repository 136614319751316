import React, { useMemo } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { regulation, regulationLink } from './regulations.module.scss';

interface IRegulationsProps {
    className?: string;
    translationKey: string;
    regulationsUrl: string;
}

const Regulations: React.FC<IRegulationsProps> = ({
    className = '',
    translationKey,
    regulationsUrl,
}) => {
    const { language, i18n } = useI18next();

    const regulationTranslations = useMemo(() => {
        return i18n.getResource(language, 'translation', translationKey);
    }, [i18n, language]);

    return (
        <p className={`${regulation} ${className}`}>
            {regulationTranslations[0]}{' '}
            <a href={regulationsUrl} className={regulationLink} target="_blank" rel="noreferrer">
                {regulationTranslations[1]}
            </a>{' '}
            {regulationTranslations[2]}.
        </p>
    );
};

export default Regulations;
