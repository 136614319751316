import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {layout, layoutLemon, layoutBirthday, wrapper, wrapperReverse, subheader, paragraphOne, header, bottleImageRatio, imageWrapper, lemonBottle} from './bottle-presentation.module.scss'
import { grid, gridExtended } from '../../styles/grid.module.scss';

import { ILabelStylePresentation } from '../../models/label-style.model';

import StyleHeader from '../atoms/style-header';
import StyleSubheader from '../atoms/style-subheader';
import StyleParagraph from '../atoms/style-paragraph';
import RatioImage from '../atoms/ratio-image';

interface ISmallBottleGift {
    bottleType: string;
    occasion: string;
    labelStyle: ILabelStylePresentation;
}

const BottlePresentation: React.FC<ISmallBottleGift> = ({ bottleType, occasion, labelStyle }) => {
    const { t } = useI18next();
    return (
        <div className={`${grid} ${gridExtended} ${layout} ${bottleType === 'lemon' ? layoutLemon : ''} ${occasion === 'birthday' ? layoutBirthday : ''}`}>
            {bottleType === 'small' &&
                <div className={wrapper}>
                    <div>
                        <StyleSubheader className={subheader} text={t('small.bottle.gift.subheader')} />
                        <StyleHeader className={header} text={t('small.bottle.gift.header')} />
                        <StyleParagraph className={paragraphOne}>
                            {t('small.bottle.gift.text.one')}
                        </StyleParagraph>
                        <StyleParagraph>{t('small.bottle.gift.text.two')}</StyleParagraph>
                    </div>
                    <div className={imageWrapper}>
                        <RatioImage
                            ratioClass={bottleImageRatio}
                            image={getImage(labelStyle.smallBottleImage.localFile)}
                            alt={t('small.bottle.gift.alt')}
                            objectFit={'contain'}
                        />
                    </div>
                </div>
            }
            {bottleType === 'lemon' &&
                <div className={`${wrapper} ${wrapperReverse}`}>
                    <div className={imageWrapper}>
                        <RatioImage
                            className={lemonBottle}
                            ratioClass={bottleImageRatio}
                            image={getImage(labelStyle.lemonBottleImage.localFile)}
                            alt={t('lemon.bottle.alt')}
                            objectFit={'contain'}
                        />
                    </div>
                    <div>
                        <StyleSubheader className={subheader} text={t('lemon.bottle.subheader')} />
                        <StyleHeader className={header} text={t('lemon.bottle.header')} />
                        <StyleParagraph className={paragraphOne}>
                            {t('lemon.bottle.text.one')}
                        </StyleParagraph>
                        <StyleParagraph>
                            {t('lemon.bottle.text.two')}
                        </StyleParagraph>
                        <br/>
                        <StyleParagraph>
                            {t('lemon.bottle.text.three')}
                        </StyleParagraph>
                    </div>
                </div>
            }
        </div>
    );
};

export default BottlePresentation;
