import React from 'react';

import { subheader } from './style-subheader.module.scss';

interface IStyleSubheader {
    className?: string;
    text: string;
}

const StyleSubheader: React.FC<IStyleSubheader> = ({ className = '', text }) => {
    return <span className={`${subheader} ${className}`}>{text}</span>;
};

export default StyleSubheader;
