import React from 'react';

import { header } from './style-header.module.scss';

interface IStyleHeader {
    className?: string;
    text: string;
}

const StyleHeader: React.FC<IStyleHeader> = ({ className = '', text }) => {
    return <h2 className={`${header} ${className}`}>{text}</h2>;
};

export default StyleHeader;
