// extracted by mini-css-extract-plugin
export var backgroundAnimation = "style-description-module--backgroundAnimation--9c219";
export var backgroundImage = "style-description-module--background-image--0b8dc";
export var backgroundImageRatio = "style-description-module--background-image-ratio--324f4";
export var contentContainer = "style-description-module--content-container--90749";
export var errorBlink = "style-description-module--error-blink--b9ef4";
export var fadeIn = "style-description-module--fade-in--ae573";
export var headerContainer = "style-description-module--header-container--2cf24";
export var image = "style-description-module--image--1fc68";
export var imageRatio = "style-description-module--image-ratio--9f06d";
export var imagesContainer = "style-description-module--images-container--2e4f0";
export var layout = "style-description-module--layout--91303";
export var subTextClass = "style-description-module--sub-text-class--32a36";
export var wrapper = "style-description-module--wrapper--d40b1";