import React from 'react';
import { Link } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import {
    box,
    name as nameClass,
    imageWrapper,
    bottleImage,
    bottleImageRatio,
    backgroundImage,
    backgroundImageRatio,
} from './style-bottle.module.scss';
import RatioImage from '../atoms/ratio-image';

interface IStylesBottle {
    name: string;
    url: string;
    bottleImageData: IGatsbyImageData;
    bottleImageAlt: string;
    backgroundImageData?: IGatsbyImageData;
    backgroundImageAlt?: string;
}

const StyleBottle: React.FC<IStylesBottle> = ({
    name,
    url,
    bottleImageAlt,
    bottleImageData,
    backgroundImageAlt,
    backgroundImageData,
}) => {
    return (
        <Link
            className={box}
            to={`${url}/?styl=${name}`}
            id={`slider-bottle-${name}`}
        >
            <span className={nameClass}>{name}</span>
            <div className={imageWrapper}>
                <RatioImage
                    className={backgroundImage}
                    ratioClass={backgroundImageRatio}
                    image={backgroundImageData}
                    alt={backgroundImageAlt}
                    objectFit={'contain'}
                />
                <RatioImage
                    className={bottleImage}
                    ratioClass={bottleImageRatio}
                    image={bottleImageData}
                    alt={bottleImageAlt}
                    objectFit={'contain'}
                    id={`slider-image-${name}`}
                />
            </div>
        </Link>
    );
};

export default StyleBottle;
