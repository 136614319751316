import React from 'react';

import { paragraph } from './style-paragraph.module.scss';

import Markdown from '../hoc/markdown';

interface IStyleParagraph {
    className?: string;
    children: string & React.ReactNode;
}

const StyleParagraph: React.FC<IStyleParagraph> = ({ className = '', children }) => {
    if (!children) return null;
    return <Markdown className={`${paragraph} ${className}`}>{children}</Markdown>;
};

export default StyleParagraph;
