import React, { useRef } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { getImage } from 'gatsby-plugin-image';

import {
    layout,
    wrapper,
    headerWrapper,
    header,
    subheader,
    slider,
} from './style-slider.module.scss';
import { grid } from '../../styles/grid.module.scss';

import { ILabelStylePresentation } from '../../models/label-style.model';

import Button from '../atoms/button';
import StyleSubheader from '../atoms/style-subheader';
import StyleHeader from '../atoms/style-header';
import StyleBottle from '../molecules/style-bottle';
import Slider from '../hoc/slider';

interface IStylesSlider {
    name: string;
    occasion: string;
    generatorUrl: string;
    labelStyles: ILabelStylePresentation[];
    onLoadStylePage: (index: number) => void;
    initialSlide: number;
}

const StyleSlider: React.FC<IStylesSlider> = ({
    name,
    labelStyles,
    onLoadStylePage,
    initialSlide,
    occasion,
    generatorUrl
}) => {
    const { t } = useI18next();

    const params = [
        {
            key: 'styl',
            value: name,
        },
    ];

    const initRef = useRef(true);

    const sliderProps = {
        centeredSlides: true,
        loop: true,
        simulateTouch: false,
        onSlideChange: function (swiper: any) {
            if (initRef.current) {
                initRef.current = false;
                return;
            }
            onLoadStylePage(swiper.realIndex);
        },
    };

    return (
        <div className={`${grid} ${layout}`}>
            <div className={wrapper}>
                <div className={headerWrapper}>
                    <StyleSubheader className={subheader} text={t('style.page.slider.subheader')} />
                    {occasion === 'wedding' &&
                        <StyleHeader className={header} text={t('style.page.slider.header.wedding')} />
                    }
                    {occasion === 'birthday' &&
                        <StyleHeader className={header} text={t('style.page.slider.header.birthday')} />
                    }
                    {occasion === 'anniversary' &&
                        <StyleHeader className={header} text={t('style.page.slider.header.anniversary')} />
                    }
                </div>
                <Slider
                    className={slider}
                    showNav="full"
                    forceArrowSpace={false}
                    loop={true}
                    activeSlide={initialSlide}
                    breakpoints={occasion === 'anniversary' ? anniversaryBreakpoints : breakpoints}
                    sliderProps={sliderProps}
                >
                    {labelStyles.map((labelStyle: ILabelStylePresentation, index: number) => {

                        const bottleImageData = getImage(labelStyle.bottleImage.localFile);

                        if (occasion === 'wedding') {

                            const backgroundImageData = getImage(
                                labelStyle.bottleBackgroundAccentImage?.localFile
                            );

                            if (backgroundImageData === undefined || bottleImageData === undefined)
                                return null;

                            return (
                                <StyleBottle
                                    key={`label_slide_${index}`}
                                    name={labelStyle.type}
                                    url={generatorUrl}
                                    bottleImageData={bottleImageData}
                                    bottleImageAlt={labelStyle.bottleImage.alternativeText}
                                    backgroundImageData={backgroundImageData}
                                    backgroundImageAlt={
                                        labelStyle.bottleBackgroundAccentImage.alternativeText
                                    }
                                />
                            );
                        }
                        else {

                            if (bottleImageData === undefined)
                                return null;

                            return (
                                <StyleBottle
                                    key={`label_slide_${index}`}
                                    url={generatorUrl}
                                    name={labelStyle.type}
                                    bottleImageData={bottleImageData}
                                    bottleImageAlt={labelStyle.bottleImage.alternativeText}
                                />
                            );
                        }
                    })}
                </Slider>
                <Button
                    as={'link'}
                    to={generatorUrl}
                    params={params}
                    variant={'outlined'}
                    id={'label-slider-cta'}
                >
                    {t('style.page.choose.style')}
                </Button>
            </div>
        </div>
    );
};

const breakpoints = {
    0: { slidesPerView: 1 },
    600: { slidesPerView: 3 },
    1260: { slidesPerView: 5 },
};

const anniversaryBreakpoints = {
    0: { slidesPerView: 1 },
    600: { slidesPerView: 3 },
}

export default StyleSlider;
